<template>
  <router-layout>
    <div class="page">
     <div class="pageHead">
       <van-nav-bar
         title="地理位置"
         left-text="返回"
         left-arrow
         class="headback"
         @click-left="Back"
       />
     </div>
      <div class="pageMapBody" id="map1"></div>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
  export default {
    data(){
      return{
        point:[],
        addRess:''
      }
    },
    mixins: [authMixin],
    created(){
        this.addRess = this.$route.query.addRess
        this.point = JSON.parse(this.$route.query.point)
    },
    mounted(){
     this.createMap()
    },
    methods:{
      createMap(){
        this.map = new AMap.Map('map1', {
          resizeEnable: true,
          center: this.point,
          zoom: 13
        })
        let marker = new AMap.Marker({
          position: this.map.getCenter(),
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png',
        })
        marker.setMap(this.map)
        let info = `<p style="padding-top:.4rem;padding-bottom: .15rem;font-size:.34rem">${this.addRess}</p>`
        const infoWindow = new AMap.InfoWindow({
        	content: info,
        	offset: new AMap.Pixel(0, -35)
        })
        infoWindow.open(this.map, this.map.getCenter());
      },
      Back(){
        this.$router.back()
      }
    }
  }
</script>

<style lang="scss">
  .headback{
    .van-icon{
      color: #555 !important;
    }
    span{
      color: #555 !important;
    }
  }
  .pageHead{
    height: 1.2rem;
  }
  .pageMapBody{
    height: calc(100vh - 1.2rem);
    width: 100%;
  }
</style>